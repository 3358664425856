<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Metric System Prefixes</h2>
      <p class="mb-3">
        There are 7 base units in the metric system from which all other units are derived. These 7
        units are as follows:
      </p>

      <v-simple-table class="pl-6 mb-5">
        <thead>
          <tr>
            <th style="font-size: 15px; height: 45px">
              <stemble-latex content="$\text{Property}$" />
            </th>
            <th style="font-size: 15px; height: 45px">
              <stemble-latex content="$\text{Standard Unit}$" />
            </th>
            <th style="font-size: 15px; height: 45px">
              <stemble-latex content="$\text{Symbol}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{Length}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{meter}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$\text{m}$" /></td>
          </tr>
          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{Mass}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{kilogram}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{kg}$" />
            </td>
          </tr>
          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{Time}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{second}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$\text{s}$" /></td>
          </tr>
          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{Temperature}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{kelvin}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$\text{K}$" /></td>
          </tr>
          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{Amount of Chemical}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{mole}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{mol}$" />
            </td>
          </tr>
          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{Electric Current}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{ampere}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$\text{A}$" /></td>
          </tr>
          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{Luminous Intensity}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{candela}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{cd}$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-3">
        Note that the standard mass unit is the kilogram. This is the only SI unit where the
        standard unit contains a prefix, specifically kilo. But any of these base units or any of
        the derived units can also contain prefixes and they are not limited to kilo. Below is a
        list of prefixes that you should be aware of:
      </p>

      <v-simple-table class="pl-6 mb-5">
        <thead>
          <tr>
            <th style="font-size: 15px; height: 45px">
              <stemble-latex content="$\text{Prefix}$" />
            </th>
            <th style="font-size: 15px; height: 45px">
              <stemble-latex content="$\text{Symbol}$" />
            </th>
            <th style="font-size: 15px; height: 45px">
              <stemble-latex content="$\text{Factor}$" />
            </th>
            <th style="font-size: 15px; height: 45px">
              <stemble-latex content="$\text{Example}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{femto}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{f}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$10^{-15}$" /></td>
            <td style="height: 45px">
              <stemble-latex content="$\text{1 femtosecond = 1 fs = 1 x 10}^{-15}\text{ s}$" />
            </td>
          </tr>

          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{pico}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{p}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$10^{-12}$" /></td>
            <td style="height: 45px">
              <stemble-latex content="$\text{2 picoseconds = 2 ps = 2 x 10}^{-12}\text{ s}$" />
            </td>
          </tr>

          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{nano}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{n}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$10^{-9}$" /></td>
            <td style="height: 45px">
              <stemble-latex content="$\text{3 nanoseconds = 3 ns = 3 x 10}^{-9}\text{ s}$" />
            </td>
          </tr>

          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{micro}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\mu$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$10^{-6}$" /></td>
            <td style="height: 45px">
              <stemble-latex
                content="$\text{4 microseconds = 4 }\mu\text{s = 4 x 10}^{-6}\text{ s}$"
              />
            </td>
          </tr>

          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{milli}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{m}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$10^{-3}$" /></td>
            <td style="height: 45px">
              <stemble-latex content="$\text{5 milliseconds = 5 ms = 5 x 10}^{-3}\text{ s}$" />
            </td>
          </tr>

          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{centi}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{c}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$10^{-2}$" /></td>
            <td style="height: 45px">
              <stemble-latex content="$\text{6 centimetres = 6 cm = 6 x 10}^{-2}\text{ m}$" />
            </td>
          </tr>

          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{deci}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{d}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$10^{-1}$" /></td>
            <td style="height: 45px">
              <stemble-latex content="$\text{5 decilitres = 5 dL = 5 x 10}^{-1}\text{ L}$" />
            </td>
          </tr>

          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{kilo}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{k}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$10^{3}$" /></td>
            <td style="height: 45px">
              <stemble-latex content="$\text{4 kilogram = 4 kg = 4 x 10}^{3}\text{ g}$" />
            </td>
          </tr>

          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{mega}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{M}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$10^{6}$" /></td>
            <td style="height: 45px">
              <stemble-latex content="$\text{3 megahertz = 3 MHz = 3 x 10}^{6}\text{ Hz}$" />
            </td>
          </tr>

          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{giga}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{G}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$10^{9}$" /></td>
            <td style="height: 45px">
              <stemble-latex content="$\text{2 gigahertz = 2 GHz = 2 x 10}^{9}\text{ Hz}$" />
            </td>
          </tr>

          <tr>
            <td style="height: 45px">
              <stemble-latex content="$\text{tera}$" />
            </td>
            <td style="height: 45px">
              <stemble-latex content="$\text{T}$" />
            </td>
            <td style="height: 45px"><stemble-latex content="$10^{12}$" /></td>
            <td style="height: 45px">
              <stemble-latex content="$\text{1 terawatt = 1 TW = 1 x 10}^{12}\text{ W}$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        With these prefixes and units in mind, answer each of the following questions regarding
        conversions between different units. Express all of your answers to 3 significant figures.
      </p>

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{a) How many centimeters are in 1 kilometer?}$" />
      </p>

      <calculation-input
        v-model="inputs.conversion1"
        class="pl-6 mb-5"
        dense
        :append-text="'$\\text{cm}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{b) How many megaseconds are in 1 nanosecond?}$" />
      </p>

      <calculation-input
        v-model="inputs.conversion2"
        class="pl-6 mb-5"
        dense
        :append-text="'$\\text{Ms}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{c) How many femtometers are in 1 terameter?}$" />
      </p>

      <calculation-input
        v-model="inputs.conversion3"
        class="pl-6 mb-5"
        dense
        :append-text="'$\\text{fm}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{d) How many decilitres are in}$" />
        <number-value :value="valD" unit="\text{microlitres}?" />
      </p>

      <calculation-input
        v-model="inputs.conversion4"
        class="pl-6 mb-5"
        dense
        :append-text="'$\\text{dL}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{e) How many milligrams are in}$" />
        <number-value :value="valE" unit="\text{gigagrams}?" />
      </p>

      <calculation-input
        v-model="inputs.conversion5"
        class="pl-6 mb-5"
        dense
        :append-text="'$\\text{mg}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{f) How many kilowatts are in}$" />
        <number-value :value="valF" unit="\text{milliwatts}?" />
      </p>

      <calculation-input
        v-model="inputs.conversion6"
        class="pl-6 mb-5"
        dense
        :append-text="'$\\text{kW}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question353',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        conversion1: null,
        conversion2: null,
        conversion3: null,
        conversion4: null,
        conversion5: null,
        conversion6: null,
      },
    };
  },
  computed: {
    valD() {
      return this.taskState.getValueBySymbol('valD').content;
    },
    valE() {
      return this.taskState.getValueBySymbol('valE').content;
    },
    valF() {
      return this.taskState.getValueBySymbol('valF').content;
    },
  },
};
</script>
